


























































































import { RouterNames } from "@/router/routernames";
import { Usuario } from "@/shared/dtos/usuario";
import departamentoModule from "@/store/modules/departamento-module";
import rolModule from "@/store/modules/rol-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class UsuariosFormulario extends Vue {
  created() {
    if (this.is_new()) {
      usuarioModule.onGetusuario(new Usuario({ id: 0, id_rol: 0 }));
    } else {
      usuarioModule.getusuario(this.$route.params.id);
    }
    rolModule.getroles();
    departamentoModule.getdepartamentos();
  }
  public get datasource() {
    return usuarioModule.usuario;
  }
  public get departamentos() {
    return departamentoModule.departamentos;
  }
  public get roles() {
    return rolModule.roles;
  }
  is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
  aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          usuarioModule
            .guardarusuario(new Usuario(this.datasource))
            .then(() => this.cancelar());
        } else {
          usuarioModule
            .modificarusuario(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }
  cancelar() {
    this.$router.push({ name: RouterNames.usuarioslista });
  }
  eliminar() {
    usuarioModule.eliminarusuario(new Usuario(this.datasource));
  }
}
