import { BaseDto } from "@/shared/dtos/base-dto";

export class rol extends BaseDto {
  public nombre!: string;
  public activo!: boolean;
  public createdby!: number;
  public updatedby!: number;
  public datecreated!: Date;
  public dateupdated!: Date;
}
