import { BaseDto } from "@/shared/dtos/base-dto";

export class Usuario extends BaseDto {
  public email!: string;
  public contra!: string;
  public nombre!: string;
  public cambiar_pwd_entrar!: boolean;
  public id_rol!: number;
  public tecnico!: boolean;
  public app_movil!: boolean;
}
