import { store } from "@/store/store";
import { departamento } from "@/shared/dtos/departamento";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "departamentoModule",
  store,
  dynamic: true,
})
class departamentoModule extends VuexModule {
  public departamentos: departamento[] = [];
  public departamento: departamento = new departamento();

  @Action({ commit: "onGetdepartamentos" })
  public async getdepartamentos() {
    return await ssmHttpService.get(API.departamentos);
  }

  @Action({ commit: "onGetdepartamentos" })
  public async getdepartamentosActivos() {
    return await ssmHttpService.get(API.departamentos + "/activos");
  }

  @Action({ commit: "onGetdepartamento" })
  public async getdepartamento(id: any) {
    return await ssmHttpService.get(API.departamentos + "/" + id);
  }

  @Action
  public async guardardepartamento(departamento: departamento) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.departamentos, departamento.toJson());
    this.getdepartamentos();
  }

  @Action
  public async modificardepartamento(departamento: departamento) {
    await ssmHttpService.put(
      API.departamentos + "/" + departamento.id,
      departamento
    );
    this.getdepartamentos();
  }

  @Action
  public async eliminardepartamento(departamento: departamento) {
    await ssmHttpService.delete(
      API.departamentos + "/" + departamento.id,
      null,
      false
    );
    this.getdepartamentos();
  }

  @Mutation
  public onGetdepartamentos(res: departamento[]) {
    this.departamentos = res ? res.map((x) => new departamento(x)) : [];
  }

  @Mutation
  public onGetdepartamento(res: departamento) {
    this.departamento = new departamento(res);
  }
}
export default getModule(departamentoModule);
